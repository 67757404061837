import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Auth } from 'aws-amplify';
import DashBoardGridPanel from './DashBoardGridPanel';

const DashBoard = ({ user, ...props }) => {
    const [customersList, setCustomersList] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [locationsList, setLocationsList] = useState();
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedLocationId, setSelectedLocationId] = useState('');
    const [transactionDate, setTransactionDate] = useState(dayjs());
    const [dailyAnalysisData, setDailyAnalysisData] = useState([]);
    const [dataError, setDataError] = useState(null)
    const [indicatorOn, setIndicatorOn] = useState(false);

    let selectedTranDate = transactionDate ? transactionDate.format("YYYY-MM-DD") : '';

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff";
    });

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchCustomersWithDC();
        })
    }, []);

    useEffect(() => {
        if (!customersList) {
            setIndicatorOn(true);
        } else {
            setIndicatorOn(false);
        }
    }, [props.customers, customersList]);

    useEffect(() => {
        if (props.siteHealthData) {
            setDailyAnalysisData(props.siteHealthData);
            setIndicatorOn(false);
        } else {
            setDailyAnalysisData([]);
        }
    }, [props.siteHealthData, dailyAnalysisData])

    useEffect(() => {
        if (props.siteHealthError || props.siteHealthError === 'Resource not found.') {
            setDataError(props.siteHealthError);
            setIndicatorOn(false);
        } else {
            setDataError(null)
        }
    }, [props.siteHealthError])

    useEffect(() => {
        setCustomersList(props.customers);
        if (selectedCustomer === 'All') {
            setSelectedCustomerId('All');
        }
        if (selectedLocation === 'All') {
            setSelectedLocationId('All');
        }
    }, [props.customers, selectedCustomer, selectedLocation]);

    const handleCustomerChange = (event) => {
        setSelectedCustomer(event);
        setSelectedCustomerId(event.customer_id);
        if (event.dcs && event.dcs[0].dc_id) {
            setLocationsList(event.dcs);
            setSelectedLocationId(event.dcs.dc_id);
        } else {
            setLocationsList();
            setSelectedLocationId();
        }
    }
    const handleLocationChange = (event) => {
        setSelectedLocation(event);
        setSelectedLocationId(event.dc_id);
    }


    const FetchDatails = () => {
        setIndicatorOn(true);
        if (selectedTranDate !== null) {
            props.fetchSiteHealthDetails(selectedCustomerId, selectedLocationId, selectedTranDate);
        }
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                {indicatorOn ?
                    <div>
                        <Backdrop
                            sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={indicatorOn}
                        >
                            <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                        </Backdrop>
                    </div> : ''
                }
                <h2>Site Health Metrics</h2>
                {/* {edgedeployData ? <Alert severity="success" sx={{ width: '50%', marginTop: '20px' }}>Success</Alert> : ''} */}
                <div style={{ width: '90%', display: 'flex' }}>
                    {customersList ?
                        <FormControl sx={{ float: 'left', width: '270px', marginRight: '10px' }}>
                            <InputLabel id="customer-label">Customer</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={selectedCustomer || ''}
                                onChange={(event) => handleCustomerChange(event.target.value)}
                                label="Customer"
                                sx={{ textAlign: 'left' }}>
                                <MenuItem key='Select All' value='All'>
                                    Select All
                                </MenuItem>
                                {customersList.map((item) => (
                                    <MenuItem key={item.customer_id} value={item}>
                                        {item.customer_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> : ""
                    }
                    {locationsList ? <FormControl sx={{ float: 'left', width: '270px', marginRight: '10px' }}>
                        <InputLabel id="dc-label">DC</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={selectedLocation || ''}
                            onChange={(event) => handleLocationChange(event.target.value)}
                            label="DC"
                            sx={{ textAlign: 'left' }}>
                            <MenuItem key='Select All' value='All'>
                                Select All
                            </MenuItem>
                            {locationsList.map((item) => (
                                <MenuItem key={item.dc_id} value={item}>
                                    {item.dc_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> : ''}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']} sx={{ marginTop: '-8px' }}>
                            <DatePicker label="Transaction Date"
                                value={transactionDate}
                                onChange={(newValue) => setTransactionDate(newValue)}
                                format="MM-DD-YYYY"
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <Button variant="contained"
                        onClick={FetchDatails}
                        disable={indicatorOn ? true : false}
                        sx={{ width: '120px', marginLeft: '10px', height: '54px', marginTop: '0px' }}>Fetch</Button>
                </div>
            </div>
            <div>
                {
                    (selectedCustomer && dailyAnalysisData) ? <DashBoardGridPanel dailyAnalysisData={dailyAnalysisData} /> : ''
                }
            </div>


        </>
    )
}

const mapStateToProps = (state) => {
    return {
        siteHealthData: state.siteHelathMetrics.siteHealthData ? state.siteHelathMetrics.siteHealthData : [],
        customers: state.siteHelathMetrics.customers ? state.siteHelathMetrics.customers : null,
        siteHealthError: state.siteHelathMetrics.siteHealthError ? state.siteHelathMetrics.siteHealthError : null,
        user: state.application.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomersWithDC: () => dispatch(Actions.LOAD_CUSTOMERS_WITH_DC()),
        fetchSiteHealthDetails: (selectedCustomerId, selectedLocationId, selectedTranDate) => dispatch(Actions.LOAD_SITE_HEALTH_METRICS(selectedCustomerId, selectedLocationId, selectedTranDate)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);