import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { weight_verified_scale_values } from '../utils.js';


const SkuUpdateTabAttribute = ({user, ...props}) => {
    const [brands, setBrands] = useState(props.brands);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedBrandId, setSelectedBrandId] = useState('');
    const [packageType, setPackageType] = useState(props.packageType);
    const [allskus, setAllskus] = useState(props.skusbydesc);
    const [selectedPackageType, setSelectedPackageType] = useState('');
    const [selectedPackageTypeId, setSelectedPackageTypeId] = useState();
    const [referenceNumber, setReferenceNumber] = useState('');
    const [searchSKU, setSearchSKU] = useState(undefined);
    const [upc, setUpc] = useState(undefined);
    const [weightVerifiedValues, setWeightVerifiedValues] = useState(weight_verified_scale_values);
    const [weight, setWeight] = useState();
    const [height, setHeight] = useState();
    const [length, setLength] = useState();
    const [width, setWidth] = useState();
    const [unitsPerCase, setUnitsPerCase] = useState();
    const [skuPackagesPerCase, setSkuPackagesPerCase] = useState();
    const [skuAttributeType, setSkuAttributeType] = useState('Search SKU');
    const [customersVorList, setCustomersVorList] = useState(props.customersVorList);
    const [skubyref, setSkubyref] = useState(props.skubyrefrence);
    const [selectedrefrence, setSelectedrefrence] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerName, setSelectedCustomerName] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [weightVerified, setWeightVerified] = useState();
    const [selectedWeightVerified, setSelectedWeightVerified] = useState('');
    const [dateweightverifiedCopy, setDateweightverifiedCopy] = useState('');
    const [dateWeightVerified, setDateWeightVerified] = useState(dayjs());
    const [indicatorOn, setIndicatorOn] = useState(false);
    const [listOpen, setListOpen] = useState(false);
    const [disable, setDisable] = useState(true);
    const [success, setSuccess] = useState(props.updateweightstatus);
    const [error, setError] = useState(props.error);


    let enable = false;
    let error1 = false;
    
    useEffect(() => {
        setCustomersVorList(props.customersVorList);
        let jwt = localStorage.getItem('authToken');
        props.fetchBrands(jwt, null);
        props.fetchPackageType(jwt, null);
        props.fetchSkusByDesc(jwt, null);
    }, []);

    useEffect(() => {
        setCustomersVorList(props.customersVorList);
    }, [props.customersVorList, selectedCustomer]);

    useEffect(() => {
        setBrands(props.brands);
        setPackageType(props.packageType);
        setAllskus(props.skusbydesc);

        if (!selectedBrandId) {
            setSelectedBrandId();
        }
        if (!selectedPackageTypeId) {
            setSelectedPackageTypeId()
        }

    }, [props.brands, props.packageType, props.skusbydesc, selectedBrandId, selectedPackageTypeId,]);

    useEffect(() => {
        if (!customersVorList || !props.skusbydesc) {
            setIndicatorOn(true);
        } else if (props.skubyrefrence) {
            setIndicatorOn(false);
        } else {
            setIndicatorOn(false);
        }
    }, [customersVorList, props.skusbydesc, props.skubyrefrence])

    useEffect(() => {
        if (selectedrefrence) {
            props.fetchSkuByRefrence(selectedrefrence);
        }
    }, [selectedrefrence])

    useEffect(() => {
        if (props.skubyrefrence) {
            setSkubyref(props.skubyrefrence);
            setSelectedBrandId(props.skubyrefrence.brand_id);
            if (props.skubyrefrence.brand_id) {
                let brandname = props.brands.map(b => b).filter((brand) => {
                    if (brand.brand_id === props.skubyrefrence.brand_id) {
                        return brand;
                    }
                });
                setSelectedBrand({ brand_id: brandname[0].brand_id, brand_name: brandname[0].brand_name });
            }
            setSelectedPackageTypeId(props.skubyrefrence.package_type_id);
            setSelectedPackageType({ package_type_id: props.skubyrefrence.package_type_id, package_name: props.skubyrefrence.package_category_name });
            setWeight(props.skubyrefrence.weight);
            setLength(props.skubyrefrence.length);
            setHeight(props.skubyrefrence.height);
            setWidth(props.skubyrefrence.width);
            setUnitsPerCase(props.skubyrefrence.units_per_case);
            setSkuPackagesPerCase(props.skubyrefrence.sku_packages_per_case);
            if (props.skubyrefrence.is_weight_verified_on_scale === false) {
                setWeightVerified({ name: props.skubyrefrence.is_weight_verified_on_scale })
                setSelectedWeightVerified(0);
            } else if (props.skubyrefrence.is_weight_verified_on_scale === true) {
                setWeightVerified({ name: props.skubyrefrence.is_weight_verified_on_scale })
                setSelectedWeightVerified(1);
            } else { setWeightVerified() }
            setReferenceNumber(props.skubyrefrence.vor_reference_number);
            setUpc(props.skubyrefrence.upc);
            if (props.skubyrefrence.date_weight_was_verified) {
                let year = props.skubyrefrence.date_weight_was_verified.slice(0, 4);
                let month = props.skubyrefrence.date_weight_was_verified.slice(5, 7);
                let day = props.skubyrefrence.date_weight_was_verified.slice(8, 10);

                let selectedDate = `${month}-${day}-${year}`;
                setDateweightverifiedCopy(selectedDate);
            }

        }
    }, [props.skubyrefrence])

    useEffect(() => {
        if (!weightVerified) {
            setSelectedWeightVerified()
            setWeightVerified()
        }
        if (!dateweightverifiedCopy === "") {
            setDateweightverifiedCopy()
        }
        if (!dateWeightVerified) {
            setDateWeightVerified();
        }
        if (!length || length === "") {
            setLength();
        }
        if (!weight || weight === "") {
            setWeight();
        }
        if (!height || height === "") {
            setHeight();
        }
        if (!width || width === "") {
            setWidth();
        }
        if (!unitsPerCase || unitsPerCase === "") {
            setUnitsPerCase();
        }
        if (!skuPackagesPerCase || skuPackagesPerCase === "") {
            setSkuPackagesPerCase();
        }
    }, [weightVerified, dateWeightVerified, length, weight, height, width])

    const handleBrandChange = (event) => {
        setSelectedBrand(event);
        setSelectedBrandId(event.brand_id);
    }
    const handlePackageTypeChange = (event) => {
        setSelectedPackageType(event);
        setSelectedPackageTypeId(event.package_type_id);
    }
    const handlesearchSKUChange = (event) => {
        setSearchSKU(event.target.value);
        setListOpen(true);
    }
    const handleReferenceChange = (event) => {
        setReferenceNumber(event.target.value);
    }
    const handleUpcChange = (event) => {
        setUpc(event.target.value);
    }
    const handleWeightChange = (event) => {
        setWeight(event.target.value);
    }
    const handleHeightChange = (event) => {
        setHeight(event.target.value);
    }
    const handleLengthChange = (event) => {
        setLength(event.target.value);
    }
    const handleWidthChange = (event) => {
        setWidth(event.target.value);
    }
    const handleSkuAttributeTypeChange = (event) => {
        setSkuAttributeType(event.target.value);
        setSkubyref('');
        setListOpen(false);
        setSearchSKU();
        setDisable(true);
        clearAllVal();
    };
    const handleUnitsPerCaseChange = (event) => {
        setUnitsPerCase(event.target.value);
    };
    const handleSkuPackagesPerCaseChange = (event) => {
        setSkuPackagesPerCase(event.target.value);
    };
    const clearAllVal = () => {
        setSelectedBrandId();
        setSelectedBrand()
        setSelectedPackageTypeId();
        setSelectedPackageType();
        setWeight();
        setLength();
        setHeight();
        setWidth();
        setUnitsPerCase();
        setSkuPackagesPerCase();
        setWeightVerified();
        setDateWeightVerified();
        setReferenceNumber();
        setUpc();
    }

    useEffect(() => {
        if (dateWeightVerified) {
            setDateweightverifiedCopy()
        } else {
            setDateWeightVerified();
        }
    }, [dateWeightVerified])

    const defaultPropsBrand = {
        options: brands,
        getOptionLabel: (option) => option.brand_name,
    };
    const defaultPropsPackageType = {
        options: packageType,
        getOptionLabel: (option) => option.package_name,
    };
    const defaultweightVerifiedScale = {
        options: weightVerifiedValues,
        getOptionLabel: (option) => option.name,
    };

    const handleVorCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
        setSelectedCustomerId(event.target.value.customer_id);
        let jwt = localStorage.getItem('authToken');
        props.fetchBrands(jwt, event.target.value.customer_id);
        props.fetchPackageType(jwt, event.target.value.customer_id);
        props.fetchSkusByDesc(jwt, event.target.value.customer_id);
    }
    const handleWeightVerifiedChange = (event) => {
        setWeightVerified(event);
        if (event.name === 'false') {
            setSelectedWeightVerified(0);
        } else if (event.name === 'true') {
            setSelectedWeightVerified(1);
        } else {
            setSelectedWeightVerified();
        }
    }

    const handleSKUvalue = (event) => {
        setSearchSKU(event.currentTarget.textContent);
        setListOpen(false);
        setSelectedrefrence(event.currentTarget.id);
    }

    const closeList = () => {
        setListOpen(false);
    }
    const SearchSkus = () => {
        setIndicatorOn(true)
        props.fetchSkuByRefrence(referenceNumber, upc);
    }

    useEffect(() => {
        if (props.updateweightstatus) {
            setSuccess(props.updateweightstatus);
        }
        if (props.error) {
            setError(props.error)
        }
        if (error !== null) {
            setIndicatorOn(false)
            callReUpdate();
        }
        if (success === 'Successfully updated vor_sku table') {
            setIndicatorOn(false);
            callReUpdate();
        }
    }, [props.error, props.updateweightstatus, error, success])

    const callReUpdate = () => {
        setTimeout(() => {
            setError(null);
            setSuccess(null);
        }, 1500);
    }

    const editDetails = () => {
        if (disable) {
            setDisable(false);
        } else {
            setIndicatorOn(true);
            let selectedDate = dateWeightVerified ? dateWeightVerified.format("MM-DD-YYYY") : undefined;
            props.updateWeight("update", upc, referenceNumber, weight, height, length, width, selectedPackageTypeId, unitsPerCase, selectedBrandId, skuPackagesPerCase, selectedWeightVerified, dateweightverifiedCopy, selectedDate);
        }

    }

    const handleTextDateWeightverified = () => {
        setDateweightverifiedCopy();
    }

    return (
        <div style={{ width: '100%' }}>
            {indicatorOn ?
                <div>
                    <Backdrop
                         sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={indicatorOn}
                    >
                        <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                    </Backdrop>
                </div> : ''
            }

            <FormControl sx={{ marginTop: '20px' }}>
                <FormLabel id="demo-row-radio-buttons-group-label">Choose 1 type</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={skuAttributeType}
                    onChange={handleSkuAttributeTypeChange}
                >
                    <FormControlLabel value="Search SKU" control={<Radio />} label="Search with the SKU Description" />
                    <FormControlLabel value="reference" control={<Radio />} label="VOR Reference Number" />
                    <FormControlLabel value="upc" control={<Radio />} label="UPC" />
                </RadioGroup>
            </FormControl>
            <Box
                sx={{
                    '& .MuiTextField-root': { width: '300px', boxShadow: 'none', marginTop: '20px', marginRight: '20px' },
                }}
                noValidate
            >
                {customersVorList && <FormControl sx={{ width: '300px', boxShadow: 'none', marginTop: '30px', marginRight: '20px' }}>
                    <InputLabel id="demo-multiple-name-label">Customers</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        label="Customers"
                        value={selectedCustomer || 'Select All'}
                        onChange={handleVorCustomerChange}
                        sx={{ textAlign: 'left' }}>
                        <MenuItem key={0} value={'Select All'}>
                            Select All
                        </MenuItem>
                        {customersVorList.map((item) => (
                            <MenuItem key={item.customer_id} value={item}>
                                {item.customer_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </Box>
            {
                customersVorList &&
                <Box
                    sx={{
                        '& .MuiTextField-root': { width: '300px', boxShadow: 'none', marginTop: '20px', marginRight: '20px' },
                    }}
                    noValidate
                >
                    {skuAttributeType === 'Search SKU' && <TextField
                        id="outlined-name"
                        label="Search with the SKU Description"
                        value={searchSKU ? searchSKU : undefined}
                        onChange={handlesearchSKUChange}
                        error={error1}
                        helperText={error1 && "Hint: Enter your text here"}
                        style={{ backgroundColor: '#fff' }}
                    />}
                    {skuAttributeType === 'reference' && <TextField
                        id="outlined-name"
                        label="VOR Reference Number*"
                        value={referenceNumber ? referenceNumber : ''}
                        onChange={handleReferenceChange}
                        error={error1}
                        helperText={error1 && "Hint: Enter your text here"}
                        style={{ backgroundColor: '#fff' }}
                    />}
                    {skuAttributeType === 'upc' && <TextField
                        id="outlined-name"
                        label="UPC*"
                        value={upc || ''}
                        onChange={handleUpcChange}
                        error={error1}
                        helperText={error1 && "Hint: Enter your text here"}
                        style={{ backgroundColor: '#fff' }}
                    />}
                    {(listOpen && skuAttributeType === 'Search SKU') ?
                        <>
                            <IconButton aria-label="cancel" color="default"
                                sx={{ position: 'absolute', margin: '65px 0px 0px -30px' }}
                                onClick={closeList}
                            >
                                <CancelPresentationIcon />
                            </IconButton>
                            <List
                                sx={{
                                    position: 'absolute', zIndex: '1030', background: '#fff',
                                    width: '300px', height: '350px', overflowY: 'scroll', border: '1px solid #ccc'
                                }}>

                                {allskus &&
                                    allskus
                                        .filter(item => item.long_description.toLowerCase().includes(searchSKU.toLowerCase()))
                                        .map((skuItem, i) => {
                                            return <ListItem key={i}
                                                id={skuItem.vor_reference_number}
                                                sx={{ fontSize: '13px', cursor: 'pointer' }}
                                                onClick={handleSKUvalue}

                                            >{skuItem.long_description}
                                            </ListItem>
                                        })
                                }
                            </List>
                        </> : ''}
                    {((referenceNumber && skuAttributeType === 'reference') || (upc && skuAttributeType === 'upc')) ?
                        <Button variant="contained"
                            sx={{ marginTop: '30px' }}
                            onClick={SearchSkus}
                        >
                            Search Sku
                        </Button> : ''
                    }
                </Box>
            }

            <div style={{ paddingTop: '40px', width: '100%', display: 'block' }}>

                {brands && <Autocomplete
                    {...defaultPropsBrand}
                    //getOptionSelected={(option, value) => option.id === value.id}
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    value={selectedBrand || null}
                    disabled={upc ? disable : enable}
                    onChange={(e, v) => handleBrandChange(v)}
                    sx={{ float: 'left', width: 300, marginRight: '20px' }}
                    renderInput={(params) => (
                        <TextField {...params} label="Brand" variant="standard" />
                    )}
                />}

                {packageType && <Autocomplete
                    {...defaultPropsPackageType}
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    disabled={upc ? disable : enable}
                    value={selectedPackageType || null}
                    onChange={(e, v) => handlePackageTypeChange(v)}
                    sx={{ float: 'left', width: 300, marginRight: '20px' }}
                    renderInput={(params) => (
                        <TextField {...params} label="Package Type" variant="standard" />
                    )}
                />}
            </div>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { width: '300px', boxShadow: 'none', marginTop: '30px', marginRight: '20px' },
                }}
                noValidate
            >
                <TextField
                    id="outlined-name"
                    label="Weight"
                    value={weight || ''}
                    disabled={upc ? disable : enable}
                    onChange={handleWeightChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                    style={{ backgroundColor: '#fff' }}
                />
                <TextField
                    id="outlined-name"
                    label="Length"
                    value={length || ''}
                    disabled={upc ? disable : enable}
                    onChange={handleLengthChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                />
                <TextField
                    id="outlined-name"
                    label="Width"
                    value={width || ''}
                    onChange={handleWidthChange}
                    disabled={upc ? disable : enable}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                />
                <TextField
                    id="outlined-name"
                    label="Height"
                    value={height || ''}
                    disabled={upc ? disable : enable}
                    onChange={handleHeightChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                />
                <TextField
                    id="outlined-name"
                    label="Units Per Case"
                    value={unitsPerCase ? unitsPerCase : ''}
                    disabled={upc ? disable : enable}
                    onChange={handleUnitsPerCaseChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                />
                <TextField
                    id="outlined-name"
                    label="SKU Packages Per Case"
                    value={skuPackagesPerCase ? skuPackagesPerCase : ''}
                    disabled={upc ? disable : enable}
                    onChange={handleSkuPackagesPerCaseChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                    style={{ backgroundColor: '#fff' }}
                />
                {weightVerifiedValues && <Autocomplete
                    {...defaultweightVerifiedScale}
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    value={weightVerified ? weightVerified : null}
                    disabled={upc ? disable : enable}
                    onChange={(e, v) => handleWeightVerifiedChange(v)}
                    sx={{ float: 'left', width: 300, marginRight: '20px' }}
                    renderInput={(params) => (
                        <TextField {...params} label="Weight verified on scale" variant="standard" />
                    )}
                />}
                {dateweightverifiedCopy ?
                    <TextField
                        id="outlined-name"
                        label="Date weight verified"
                        value={dateweightverifiedCopy || ''}
                        disabled={upc ? disable : enable}
                        onClick={handleTextDateWeightverified}
                        helperText={"Hint: MM-DD-YYYY"}
                        style={{ backgroundColor: '#fff' }}
                    /> : ''}
                {!dateweightverifiedCopy ?
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="Date weight verified"
                                value={dateWeightVerified}
                                disabled={upc ? disable : enable}
                                onChange={(newValue) => setDateWeightVerified(newValue)}
                                format="MM-DD-YYYY"
                            />
                        </DemoContainer>
                    </LocalizationProvider> : ''
                }

                {(skuAttributeType === 'reference' || skuAttributeType === 'Search SKU') ? <TextField
                    id="outlined-name"
                    label="UPC"
                    value={upc || ''}
                    disabled={upc ? disable : enable}
                    onChange={handleUpcChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                    style={{ backgroundColor: '#fff' }}
                /> : ''}
            </Box>
            <Box sx={{ display: 'flex' }}>
                {(skubyref && user.canWrite) ?
                    <div style={{ paddingTop: '30px' }}>
                        <Button variant="contained"
                            onClick={editDetails}
                            disabled={false}
                            sx={{ width: '200px' }}>{disable ? 'Edit' : 'Submit'}</Button>
                    </div> : ''}
            </Box>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        brands: state.skuUpdate.brands,
        packageType: state.skuUpdate.packageType,
        skusbydesc: state.skuUpdate.skusbydesc,
        skubyrefrence: state.skuUpdate.skubyrefrence,
        error: state.skuUpdate.error,
        updateweightstatus: state.skuUpdate.updateweightstatus,
        user: state.application.user

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateWeight: (action, upc, reference, weight, height, length, width, packageTypeId, unitsPerCase, brandId, skuPackagesPerCase, isWeightVerified, dateweightverifiedCopy, selectedDate) => dispatch(Actions.UPDATE_WEIGHT(action, upc, reference, weight, height, length, width, packageTypeId, unitsPerCase, brandId, skuPackagesPerCase, isWeightVerified, dateweightverifiedCopy, selectedDate)),
        fetchBrands: (authToken, customerId) => dispatch(Actions.LOAD_BRANDS(authToken, customerId)),
        fetchPackageType: (authToken, customerId) => dispatch(Actions.LOAD_PACKAGE_TYPE(authToken, customerId)),
        fetchSkusByDesc: (authToken, customerId) => dispatch(Actions.LOAD_SKUSBY_DESC(authToken, customerId)),
        fetchSkuByRefrence: (referenceNumber, upc) => dispatch(Actions.LOAD_SKUSBY_REFRENCE(referenceNumber, upc))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuUpdateTabAttribute);