import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled, Paper } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import TextField from '@mui/material/TextField';
import './style.css';

import { Auth } from 'aws-amplify';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '20px',
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));


const CICDUIDeploy = ({ user, ...props }) => {
    const [customersList, setCustomersList] = useState(props.customers);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedDeploy, setSelectedDeploy] = useState('Full customer deployment');
    const [isFullCustomerDeploy, setIsFullCustomerDeploy] = useState(false);
    const [isFullDcDeploy, setIsFullDcDeploy] = useState(false);
    const [isEdgePcDeploy, setIsEdgePcDeploy] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [applicationName, setApplicationName] = useState('');
    const [applicationId, setApplicationId] = useState();
    const [selectedAppId, setSelectedAppId] = useState('');
    const [locationsList, setLocationsList] = useState();
    const [edgePCList, setEdgePCList] = useState(props.edgePCs);
    const [edgePCError, setEdgePCError] = useState(props.edgePCserror);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedEdgePC, setSelectedEdgePC] = useState('');
    const [selectedEdgePcId, setSelectedEdgePcId] = useState('');
    const [selectedLocationId, setSelectedLocationId] = useState('');
    const [deploymentType, setDeploymentType] = useState('')
    const [selectedDate, setSelectedDate] = useState(dayjs() || undefined);
    const [vorApplications, setVorApplications] = useState(props.applicationsList);
    const [deploymentTypesList, setDeploymentTypesList] = useState(props.deploymentTypeList);
    const [edgedeploySuccess, setEdgedeploySuccess] = useState(props.edgeDeploySuccess);
    const [displayError, setDisplayError] = useState(props.edgeDisplayError);
    const [displaydateError, setDisplaydateError] = useState(props.edgeDisplayError);
    const [edgedeployData, setEdgedeployData] = useState();
    const [modelSetid, setModelSetid] = useState();
    const [indicatorOn, setIndicatorOn] = useState(false);


    var scheduleDate = selectedDate ? selectedDate.format('YYYY-MM-DD HH:mm:ss') : undefined;

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff"
    });

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchCustomersWithDC();
        })
    }, []);

    useEffect(() => {
        if(!customersList || !vorApplications || !deploymentTypesList) {
            setIndicatorOn(true);
        }else{
            setIndicatorOn(false);
        }
    }, [customersList, vorApplications, deploymentTypesList]);

    useEffect(() => {
        setIsFullCustomerDeploy(false);
        setIsFullDcDeploy(false);
        setIsEdgePcDeploy(false);
        if (selectedDeploy === 'Full customer deployment') {
            setIsFullCustomerDeploy(true)
            setSelectedLocation();
        } else if (selectedDeploy === 'Full dc deployment') {
            setIsFullDcDeploy(true)
        } else if (selectedDeploy === 'Edge PC deployment') {
            setIsEdgePcDeploy(true)
            setSelectedLocation(selectedCustomer.dcs[0]);
            if (selectedLocation) {
                props.fetchEdgePC(selectedLocation.dc_id);
            }
        } else {
            setIsFullCustomerDeploy(false);
            setIsFullDcDeploy(false);
            setIsEdgePcDeploy(false);
        }
    }, [selectedDeploy, isFullCustomerDeploy, isFullDcDeploy, isEdgePcDeploy])

    useEffect(() => {
        setCustomersList(props.customers);
        if (props.customers) {
            props.fetchVORApplication();
            setSelectedCustomer(props.customers[0]);
            setSelectedCustomerId(props.customers[0].customer_id)
            setEdgePCList(props.edgePCs);

            if (props.customers[0].dcs) {
                setLocationsList(props.customers[0].dcs);
                setSelectedLocationId(props.customers[0].dcs.dc_id);
            } else {
                setLocationsList();
                setSelectedLocationId();
            }
        }
    }, [props.customers]);

    useEffect(() => {
        setEdgePCList(props.edgePCs);
    }, [props.edgePCs]);

    useEffect(() => {
        if (props.edgePCserror === 'Resource not found.') {
            setEdgePCList([])
        }
    }, [selectedLocationId, props.edgePCserror])


    useEffect(() => {
        if (props.applicationsList.length == 0) props.fetchVORApplication();
        if (props.applicationsList) {
            setVorApplications(props.applicationsList);
        }
    }, [props.applicationsList])

    useEffect(() => {
        if (props.deploymentTypeList.length == 0) props.fetchDeploymentTypes();
        if (props.deploymentTypeList) {
            setDeploymentTypesList(props.deploymentTypeList);
        }
    }, [props.deploymentTypeList])

    const handleCustomerChange = (event) => {
        setApplicationName();
        setSelectedCustomer(event);
        setEdgePCList();
        setSelectedCustomerId(event.customer_id);
        if (event.dcs && event.dcs[0].dc_id) {
            setLocationsList(event.dcs);
            setSelectedLocationId(event.dcs.dc_id);
        } else {
            setLocationsList();
            setSelectedLocationId();
        }
    }
    const handleLocationChange = (event) => {
        setApplicationName();
        setSelectedLocation(event);
        setSelectedLocationId(event.dc_id);
        props.fetchEdgePC(event.dc_id);
    }
    const handleEdgePcChange = (event) => {
        setSelectedEdgePC(event);
        setApplicationName(event.application_name);
        setApplicationId(event.application_type_id);
        setSelectedAppId(event.application_type_id);
        setSelectedEdgePcId(event.edge_pc_id);
        props.fetchEdgePcConfiguration(event.edge_pc_id);
    }

    useEffect(() => {
        if (deploymentType === 1 || deploymentType === 4) {
            setApplicationId(props.applicationsList[0].application_type_id);
        }
    }, [deploymentType])

    const handleselectedDeployChange = (event) => {
        setSelectedDeploy(event.target.value);
    }

    const handleDeployTypeChange = (event) => {
        setDeploymentType(event.target.value);
        setDisplayError(false);
    }

    const handleAppTypeChange = (event) => {
        setApplicationId(event.target.value);
    }
    const handlemodelSetid = (event) => {
        setModelSetid(event.target.value.replace(/[^0-9\.]/g, ''));
    }
    useEffect(() => {
        setTimeout(() => {
            if (edgedeployData) {
                setEdgedeployData()
            }
        }, 3000)
    }, [edgedeployData]);

    useEffect(() => {
        if (props.edgedeployment !== null) {
            setEdgedeployData(props.edgedeployment)
            setIndicatorOn(false);
        }else if(props.edgeDeployError !== null){
            setIndicatorOn(false);
        }
        else{return () => {}}
    }, [props.edgedeployment, props.edgeDeployError]);

    useEffect(() => {
        if (modelSetid === "") {
            setModelSetid(undefined)
        }
    }, [modelSetid])

    const SubmitDetails = () => {
        if (deploymentType === "") {
            setDisplayError(true);
        }
        if (deploymentType !== "" && selectedDate !== undefined) {
            setDisplayError(false);
            setIndicatorOn(true);
            props.createEdgeDeploy(selectedCustomerId, selectedLocationId, selectedEdgePcId, deploymentType, modelSetid, scheduleDate, applicationId, isFullCustomerDeploy, isFullDcDeploy, isEdgePcDeploy)
        }

    }

    return (
        <div style={{ width: '100%' }}>
            {indicatorOn ?
                <div>
                    <Backdrop 
                        sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={indicatorOn}
                    >
                        <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                    </Backdrop>
                </div> : ''
            }
            <div>
                <h3 style={{ textAlign: 'left' }}>Deployment</h3>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} sx={{ marginBottom: '20px' }}>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectedDeploy}
                                    onChange={handleselectedDeployChange}
                                >
                                    <FormControlLabel value="Full customer deployment" control={<Radio />} label="Full Customer Deployment" />
                                    <FormControlLabel value="Full dc deployment" control={<Radio />} label="Full DC Deployment" />
                                    <FormControlLabel value="Edge PC deployment" control={<Radio />} label="Edge PC Deployment" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            {(isFullCustomerDeploy === true || isFullDcDeploy === true || isEdgePcDeploy === true) && customersList ? <FormControl sx={{ float: 'left', width: '90%' }}>
                                <InputLabel id="customer-label">Customer</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={selectedCustomer || ''}
                                    onChange={(event) => handleCustomerChange(event.target.value)}
                                    label="Customer"
                                    sx={{ textAlign: 'left' }}>
                                    {customersList.map((item) => (
                                        <MenuItem key={item.customer_id} value={item}>
                                            {item.customer_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : ''}
                        </Grid>
                        <Grid item xs={3}>
                            {(isFullDcDeploy === true || isEdgePcDeploy === true) && locationsList ? <FormControl sx={{ float: 'left', width: '90%' }}>
                                <InputLabel id="dc-label">DC</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={selectedLocation || ''}
                                    onChange={(event) => handleLocationChange(event.target.value)}
                                    label="DC"
                                    sx={{ textAlign: 'left' }}>
                                    {locationsList.map((item) => (
                                        <MenuItem key={item.dc_id} value={item}>
                                            {item.dc_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : ''}
                        </Grid>
                        <Grid item xs={3}>
                            {(isEdgePcDeploy === true) ? edgePCList && <FormControl sx={{ float: 'left', width: '90%' }}>
                                <InputLabel id="dc-label">Edge PC</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={selectedEdgePC || ''}
                                    onChange={(event) => handleEdgePcChange(event.target.value)}
                                    label="Edge pc"
                                    sx={{ textAlign: 'left' }}>
                                    {edgePCList.map((item) => (
                                        <MenuItem key={item.edge_pc_id} value={item}>
                                            {item.pc_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : ''
                            }

                        </Grid>
                    </Grid>
                    <Grid container spacing={0} sx={{ marginTop: '30px' }}>
                        <Grid item xs={3}>
                            <FormControl sx={{ width: '90%' }}>
                                <InputLabel id="demo-simple-select-label">Deployment Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className={displayError ? 'mandatory' : ''}
                                    value={deploymentType || ''}
                                    label="Deployment Type"
                                    onChange={handleDeployTypeChange}
                                >
                                    {deploymentTypesList && deploymentTypesList.map((dItem, index) => {
                                        return <MenuItem key={index} value={dItem.deployment_type_id}>{dItem.deployment_type_name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl sx={{ width: '90%' }}>
                                <InputLabel id="demo-simple-select-label">Application Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={{}}
                                    disabled={deploymentType === 1 || deploymentType === 4}
                                    value={applicationId || ''}
                                    label="Application Type"
                                    onChange={handleAppTypeChange}
                                >
                                    {vorApplications && vorApplications.map((vItem, index) => {
                                        return <MenuItem key={index} value={vItem.application_type_id}>{vItem.application_name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        {deploymentType === 4 ?
                            <Grid item xs={3}>
                                <TextField
                                    id="outlined-name"
                                    label="Model set id"
                                    className={displayError ? 'mandatory' : ''}
                                    value={modelSetid}
                                    onChange={handlemodelSetid}
                                    helperText="Hint: Please enter existing model set id"
                                    sx={{ backgroundColor: '#fff', width: '90%' }}
                                />
                            </Grid> : ''
                        }
                    </Grid>
                    <Grid container spacing={0} sx={{ marginTop: '40px' }}>
                        <Grid item xs={3} sx={{ marginTop: '-8px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker sx={{ width: '90%' }}
                                        label="Choose Date and Time"
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        slotProps={{
                                            textField: {
                                                helperText: 'Date & Time must be in UTC Time Zone',
                                            },
                                        }}
                                        value={selectedDate}
                                        onChange={(newValue) => setSelectedDate(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Button variant="contained" sx={{ marginTop: '30px' }}
                        onClick={SubmitDetails}
                        disable={indicatorOn ? true: false}
                    >
                        Schedule Deploy
                    </Button>
                </Box>
                {props.edgeDeployError && <Alert severity="error" sx={{ width: '50%', marginTop: '20px' }}>{props.edgeDeployError}</Alert>}
                {/* {edgedeployData ? <Alert severity="success" sx={{ width: '50%', marginTop: '20px' }}>Success</Alert> : ''} */}
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        applicationsList: state.cicdUIDeploy.applicationList ? state.cicdUIDeploy.applicationList : [],
        deploymentTypeList: state.cicdUIDeploy.deploymentTypeList ? state.cicdUIDeploy.deploymentTypeList : [],
        applicationListError: state.cicdUIDeploy.applicationListError,
        edgedeployment: state.cicdUIDeploy.edgedeployment,
        customers: state.cicdUIDeploy.customers,
        edgeDeploySuccess: state.cicdUIDeploy.edgeDeploySuccess,
        edgeDeployError: state.cicdUIDeploy.edgeDeployError,
        submitState: state.cicdUIDeploy.submitState,
        edgePCs: state.cicdUIDeploy.edgePCs ? state.cicdUIDeploy.edgePCs : [],
        edgePCserror: state.cicdUIDeploy.edgePCserror,
        edgeDisplayError: state.cicdUIDeploy.edgeDisplayError,
        user: state.application.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchDeploymentTypes: () => dispatch(Actions.LOAD_DEPLOYMENT_TYPES()),
        fetchVORApplication: () => dispatch(Actions.LOAD_VOR_APPLICATIONS()),
        fetchCustomersWithDC: () => dispatch(Actions.LOAD_CUSTOMERS_WITH_DC()),
        fetchEdgePC: (distributionId) => dispatch(Actions.LOAD_EDGEPCS(distributionId)),
        fetchEdgePcConfiguration: (edgePcId) => dispatch(Actions.LOAD_EDGEPCS_CONFIGURATION(edgePcId)),
        fetchPropertyNames: (applicationId, edgePcId) => dispatch(Actions.LOAD_PROPERTY_NAMES(applicationId, edgePcId)),
        createEdgeDeploy: (selectedCustomerId, selectedLocationId, selectedEdgePcId, deploymentType, modelSetid, scheduleDate, applicationId, isFullCustomerDeploy, isFullDcDeploy, isEdgePcDeploy) => dispatch(Actions.CREATE_EDGE_DEPLOYMENT(selectedCustomerId, selectedLocationId, selectedEdgePcId, deploymentType, modelSetid, scheduleDate, applicationId, isFullCustomerDeploy, isFullDcDeploy, isEdgePcDeploy))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CICDUIDeploy);