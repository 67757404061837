import { useState, useEffect } from 'react';
import './styles/styles.scss'
import './App.css';
import './styles.css';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Authenticator, Button } from '@aws-amplify/ui-react';
import { Typography } from "@mui/material";
import '@aws-amplify/ui-react/styles.css';
import Logo from "./assets/companyLogo_updated.png";
import styles from './styles.module.css';
import ProtectedApp from "./ProtectedApp"

const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTHORITY,
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URI,
      redirectSignOut: process.env.REACT_APP_REDIRECT_URI,
      responseType: "code"
    }
  }

}
Amplify.configure(awsConfig);




const components = {
  Header() {
    return (
      <div>
        <img src={Logo} alt="img" style={{ width: "280px", height: "90px", marginTop: "20px" }} />
        <Typography variant="h5" component="h2" className={styles.titles} style={{paddingTop: '30px'}}>
          CENTRAL ADMIN PORTAL
        </Typography>
      </div>
    );
  },
};

function App(props) {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);

  function getToken() {
    return Auth.currentSession()
      .then(session => session)
      .catch(err => console.log(err));
  }

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setUser(data);
          break;
        case "signOut":
          getToken();
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => setUser(currentUser))
      .catch((response) => console.log('response.data.Username'));

    return unsubscribe;
  }, []);

  return (
    <div className={!user ? 'login-panel' : ""}>
      <Authenticator slot="sign-in" hideSignUp components={components}>
        <ProtectedApp />
      </Authenticator>
      {!user ?
        <div className='sso-cont'>
          <Button className='SSO-button' onClick={() => Auth.federatedSignIn({ provider: "rpcidentityprovider" })}>Rehrig User? Click Here</Button>
        </div>
        : ""
      }
    </div>
  );
}
export default App


