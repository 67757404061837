/*import { all, fork, spawn, takeEvery } from 'redux-saga/effects';
import CMEffectSagas from './customerManagement';
import EdgePCEffectSagas from './edgepcs';

const sagas = [
    ...CMEffectSagas,
    ...EdgePCEffectSagas
];
export default function* rootSaga() {
    yield* sagas.map(saga => fork(saga[0],saga[1]));
}
*/
import * as Actions from '../actions/actions';
import * as AppActions from '../actions/application';
import * as ActionTypes from '../actions/actiontypes';
import * as Service from '../services/services';
import { takeEvery, call, put, all, delay } from 'redux-saga/effects'
import * as EdgePCActions from '../actions/edgepcs';

export function* fetchApplications(action) {
    try {
        const data = yield call(Service.getApplications, action.payload.authToken)
        yield put(Actions.LOAD_APPLICATIONS_SUCCESS_TYPE(data))

    } catch (error) {
        yield put(Actions.LOAD_APPLICATIONS_FAILURE_TYPE(error))
    }

}
export function* fetchVORApplications(action) {
    try {
        const data = yield call(Service.getVORApplications, action.payload.authToken)
        yield put(Actions.LOAD_VOR_APPLICATIONS_SUCCESS_TYPE(data))

    } catch (error) {
        yield put(Actions.LOAD_VOR_APPLICATIONS_FAILURE_TYPE(error))
    }

}
export function* fetchDeploymentTypes(action) {
    try {
        const data = yield call(Service.getDeploymentTypes, action.payload.authToken)
        yield put(Actions.LOAD_DEPLOYMENT_TYPES_WITH_SUCCESS(data))

    } catch (error) {
        yield put(Actions.LOAD_DEPLOYMENT_TYPES_WITH_ERROR(error))
    }

}

export function* fetchCustomers(action) {
    try {
        const data = yield call(Service.getCustomers, action.payload.authToken);

        yield put(Actions.LOAD_CUSTOMERS_SUCCESS_TYPE(data))
        if (data.status === 200) {
        }
    } catch (error) {
        yield put(Actions.LOAD_CUSTOMERS_ERROR(error))
    }

}
export function* addCustomer(action) {
    try {
        const data = yield call(Service.addCustomer, action.payload.customername, action.payload.shortname, action.payload.status, action.payload.applications)
        yield put(Actions.CREATE_CUSTOMER_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedCustomerList()
        }
    } catch (error) {
        yield put(Actions.CREATE_CUSTOMER_ERROR(error))
    }
}
export function* editCustomer(action) {
    try {
        const data = yield call(Service.editCustomer, action.payload.customerid, action.payload.customername, action.payload.shortname, action.payload.status, action.payload.applications)
        yield put(Actions.EDIT_CUSTOMER_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedCustomerList()
        }
    } catch (error) {
        yield put(Actions.EDIT_CUSTOMER_ERROR(error))
    }
}
export function* fetchCustomersWithDC() {
    try {
        const data = yield call(Service.fetchCustomersWithDC)
        yield all([
            put(EdgePCActions.LOAD_CUSTOMERS_WITH_DC_SUCCESS(data)),
        ]);

    } catch (error) {
        yield all([
            put(Actions.LOAD_APPLICATIONS_FAILURE_TYPE(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in loading customers. Please try again.",
                show: true
            }))
        ]);
    }

}
export function* fetchEdgePC(action) {
    try {
        const data = yield call(Service.fetchEdgePC, action.payload.distributionId)
        yield put(Actions.LOAD_EDGEPCS_WITH_SUCCESS(data))

    } catch (error) {
        yield all([
            put(EdgePCActions.LOAD_EDGEPCS_WITH_ERROR(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in fetching Edge PC. Please try again.",
                show: true
            }))
        ]);

    }

}
export function* fetchEdgePC2(action) {
    try {
        const data = yield call(Service.fetchEdgePC, action.payload.distributionId)
        yield put(Actions.LOAD_EDGEPCS_TWO_WITH_SUCCESS(data))

    } catch (error) {
       yield all([
                put(Actions.LOAD_EDGEPCS_TWO_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in fetching Edge PC. Please try again.",
                    show: true
                }))
            ]);

    }

}
export function* fetchEdgePcConfiguration(action) {
    try {
        const data = yield call(Service.fetchEdgePcConfiguration, action.payload.edgePcId)
        yield put(Actions.LOAD_EDGEPCS_CONFIGURATION_WITH_SUCCESS(data))

    } catch (error) {
        yield put(Actions.LOAD_EDGEPCS_CONFIGURATION_WITH_ERROR(error))
    }

}

export function* addEdgePC(action) {
    try {
        const data = yield call(Service.addEdgePC, action.payload.edgePC);
        yield all([
            put(EdgePCActions.ADD_EDGE_PC_WITH_SUCCESS(data.data)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "success",
                message: "EdgePC added successfully",
                show: true
            }))
        ]);

    } catch (error) {
        if (error.response.status === 409) {
            yield all([
                put(EdgePCActions.ADD_EDGE_PC_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error due to : Ip address conflicts with another active device at this distribution center.",
                    show: true
                }))
            ]);
        }
        else {
            yield all([
                put(EdgePCActions.ADD_EDGE_PC_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in adding Edge PC",
                    show: true
                }))
            ]);
        }
    }

}

export function* editEdgePC(action) {
    try {
        const data = yield call(Service.editEdgePC, action.payload.edgePC, action.payload.edgePCId);
        yield all([
            put(EdgePCActions.EDIT_EDGE_PC_WITH_SUCCESS(data.data)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "success",
                message: data.data,
                show: true
            }))
        ]);


    } catch (error) {
        if (error.response.status === 409) {
            yield all([
                put(EdgePCActions.ADD_EDGE_PC_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error due to : Ip address conflicts with another active device at this distribution center.",
                    show: true
                }))
            ]);
        }
        else {
            yield all([
                put(EdgePCActions.ADD_EDGE_PC_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in Editing Edge PC",
                    show: true
                }))
            ]);
        }
    }

}
export function* fetchTandems(action) {
    try {
        const data = yield call(Service.fetchTandems, action.payload.distributionId)
        yield put(EdgePCActions.LOAD_TANDEMS_WITH_SUCCESS(data))

    } catch (error) {
        if (error.response.status === 404) {
            yield put(EdgePCActions.LOAD_TANDEMS_WITH_ERROR(error))
        } else {
            yield all([
                put(EdgePCActions.LOAD_TANDEMS_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in fetching tandems. Please try again.",
                    show: true
                }))
            ]);
        }

    }

}
export function* fetchEdgeSerials(action) {
    try {
        const data = yield call(Service.getEdgeSerials, action.payload.deviceType)
        yield put(EdgePCActions.LOAD_EDGE_SERIALS_WITH_SUCCESS(data))

    } catch (error) {
        if (error.response.status !== 404) {
            yield put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in fetching edge serials. Please try again.",
                show: true
            }))
        } else {
            yield put(EdgePCActions.LOAD_EDGE_SERIALS_WITH_ERROR(error))
            yield all([
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in fetching edge serials.",
                    show: true
                }))
            ]);
        }
    }

}

export function* addTandem(action) {
    try {
        const data = yield call(Service.addTandem, action.payload.tandem);
        yield all([
            put(EdgePCActions.ADD_TANDEM_WITH_SUCCESS({ status: "success" })),
            put(AppActions.SHOW_NOTIFICATION({
                type: "success",
                message: "Tandem added successfully",
                show: true
            }))
        ]);


    } catch (error) {
        yield all([
            put(EdgePCActions.ADD_TANDEM_WITH_ERROR(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in adding new tandem",
                show: true
            }))
        ]);
    }

}

export function* editTandem(action) {
    try {
        const data = yield call(Service.editTandem, action.payload.tandem, action.payload.tandemId);
        yield all([
            put(EdgePCActions.EDIT_TANDEM_WITH_SUCCESS({ status: "success" })),
            put(AppActions.SHOW_NOTIFICATION({
                type: "success",
                message: "Tandem editted succesffully",
                show: true
            }))
        ]);


    } catch (error) {
        yield all([
            put(EdgePCActions.EDIT_TANDEM_WITH_ERROR(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in editting tandem",
                show: true
            }))
        ]);
    }

}
export function* deleteTandem(action) {
    try {
        const data = yield call(Service.deleteTandem, action.payload.tandemId);
        yield all([
            put(EdgePCActions.EDIT_TANDEM_WITH_SUCCESS({ status: "success" })),
            put(AppActions.SHOW_NOTIFICATION({
                type: "success",
                message: "Tandem deleted succesffully",
                show: true
            }))
        ]);


    } catch (error) {
        yield all([
            put(EdgePCActions.EDIT_TANDEM_WITH_ERROR(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in deleting tandem",
                show: true
            }))
        ]);
    }

}
export function* fetchLocations(action) {
    try {
        const data = yield call(Service.getLocations, action.payload.authToken)
        yield put(Actions.LOAD_LOCATIONS_SUCCESS_TYPE(data))

    } catch (error) {
        // yield put(Actions.LOAD_APPLICATIONS_FAILURE_TYPE(error))
    }

}
export function* addLocation(action) {
    try {
        const data = yield call(Service.addLocation, action.payload.customerId, action.payload.locationName, action.payload.address, action.payload.latitude, action.payload.longitude, action.payload.status, action.payload.email, action.payload.timezone)
        yield put(Actions.CREATE_LOCATION_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedLocationList()
        }
    } catch (error) {
        yield put(Actions.CREATE_LOCATION_ERROR(error))
    }
}
export function* editLocation(action) {
    try {
        const data = yield call(Service.editLocation, action.payload.locationId, action.payload.customerId, action.payload.locationName, action.payload.address, action.payload.latitude, action.payload.longitude, action.payload.status, action.payload.email, action.payload.timezone)
        yield put(Actions.EDIT_LOCATION_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedLocationList()
        }
    } catch (error) {
        yield put(Actions.EDIT_LOCATION_ERROR(error))
    }
}
export function* addVorConfiguration(action) {
    try {
        const data = yield call(Service.addVorConfiguration, action.payload.customerId, action.payload.dcId, action.payload.edgePcId, action.payload.propertyName, action.payload.propertyValue, action.payload.status)
        yield put(Actions.CREATE_VOR_CONFIGURATION_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedConfigurationList()
        }
    } catch (error) {
        yield put(Actions.CREATE_VOR_CONFIGURATION_ERROR(error))
    }
}
export function* editVorConfiguration(action) {
    try {
        const data = yield call(Service.editVorConfiguration, action.payload.configurationId, action.payload.customerId, action.payload.dcId, action.payload.edgePcId, action.payload.propertyName, action.payload.propertyValue, action.payload.status)
        yield put(Actions.EDIT_VOR_CONFIGURATION_SUCCESS(data))

        if (data.status === 200) {
            action.payload.callUpdatedConfigurationList()
        }
    } catch (error) {
        yield put(Actions.EDIT_VOR_CONFIGURATION_ERROR(error))
    }
}
export function* addVorConfigurationsList(action) {
    try {
        const data = yield call(Service.addVorConfigurationsList, action.payload.customerId, action.payload.dcId, action.payload.edgePcId, action.payload.properties);
        if (data.status === 200) {
            action.payload.callUpdatedConfigurationList()
        }
        yield put(Actions.COPY_CREATE_VOR_CONFIGURATION_SUCCESS(data))
    } catch (error) {
        yield put(Actions.COPY_CREATE_VOR_CONFIGURATION_ERROR(error))
    }
}

export function* fetchPropertyNames(action) {
    try {
        const data = yield call(Service.fetchPropertyNames, action.payload.applicationId, action.payload.edgePcId)
        yield put(Actions.LOAD_PROPERTY_NAMES_SUCCESS(data));

    } catch (error) {
        yield put(Actions.LOAD_PROPERTY_NAMES_ERROR(error))
    }

}
export function* fetchPropertyNamesEdit(action) {
    try {
        const data = yield call(Service.fetchPropertyNamesEdit, action.payload.applicationId)
        yield put(Actions.LOAD_PROPERTY_NAMES_EDIT_SUCCESS(data));

    } catch (error) {
        yield put(Actions.LOAD_PROPERTY_NAMES_EDIT_ERROR(error))
    }

}
export function* fetchBrands(action) {
    try {
        const data = yield call(Service.fetchBrands, action.payload.authToken, action.payload.customerId);
        yield put(Actions.LOAD_BRANDS_SUCCESS(data));
    } catch (error) {
        yield all([
            yield put(Actions.LOAD_BRANDS_ERROR(error)),
        ]);
    }

}
export function* fetchPackageType(action) {
    try {
        const data = yield call(Service.fetchPackageType, action.payload.authToken, action.payload.customerId);
        yield put(Actions.LOAD_PACKAGE_TYPE_SUCCESS(data));


    } catch (error) {
        yield all([
            put(Actions.LOAD_PACKAGE_TYPE_ERROR(error))
        ]);
    }

}
export function* fetchSkusByDesc(action) {
    try {
        const data = yield call(Service.getSkusByDesc, action.payload.authToken, action.payload.customerId);
        yield put(Actions.LOAD_SKUSBY_DESC_WITH_SUCCESS(data));


    } catch (error) {
        yield all([
            put(Actions.LOAD_SKUSBY_DESC_WITH_ERROR(error))
        ]);
    }

}
export function* fetchSkuByRefrence(action) {
    try {
        const data = yield call(Service.getSkuByVORRefrenceNumber, action.payload.referenceNumber, action.payload.upc);
        yield put(Actions.LOAD_SKUSBY_REFRENCE_WITH_SUCCESS(data));


    } catch (error) {
        yield all([
            put(Actions.LOAD_SKUSBY_REFRENCE_WITH_ERROR(error))
        ]);
    }

}
export function* updateWeight(action) {
    try {
        const data = yield call(Service.updateWeight,
            action.payload.action, action.payload.upc, action.payload.reference,
            action.payload.weight, action.payload.height, action.payload.length,
            action.payload.width, action.payload.packageTypeId,
            action.payload.unitsPerCase, action.payload.brandId,
            action.payload.skuPackagesPerCase, action.payload.isWeightVerified,
            action.payload.dateweightverifiedCopy,
            action.payload.selectedDate);
        yield all([
            put(Actions.UPDATE_WEIGHT_SUCCESS(data)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "success",
                message: "Updated succesffully",
                show: true
            }))
        ]);
    } catch (error) {
        yield all([
            put(Actions.UPDATE_WEIGHT_ERROR(error))
        ]);
    }

}
export function* mergeWeight(action) {
    try {
        const data = yield call(Service.mergeWeight, action.payload.action, action.payload.customerId, action.payload.delPackageTypeId, action.payload.mergePackageTypeId);
        yield all([
            put(Actions.MERGE_WEIGHT_SUCCESS(data)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "success",
                message: "Updated succesffully",
                show: true
            }))
        ]);


    } catch (error) {
        yield all([
            put(Actions.MERGE_WEIGHT_ERROR(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in merging weight",
                show: true
            }))
        ]);
    }

}

export function* fetchVorCustomers(action) {
    try {
        const data = yield call(Service.getVorCustomers);

        yield put(Actions.LOAD_VOR_CUSTOMERS_SUCCESS(data))
        if (data.status === 200) {
        }
    } catch (error) {
        yield put(Actions.LOAD_VOR_CUSTOMERS_ERROR(error))
    }

}

export function* fetchRoles() {
    try {
        const data = yield call(Service.getRoles);

        yield put(Actions.LOAD_ROLES_SUCCESS_TYPE(data))
        if (data.status === 200) {
        }
    } catch (error) {
        yield put(Actions.LOAD_ROLES_ERROR(error))
    }
}

export function* fetchLocationsByCustomer(action) {
    try {
        const data = yield call(Service.getLocations, action.payload.selectedCustomerId);
        yield put(Actions.LOAD_LOCATIONS_SUCCESS_TYPE(data))
        if (data.status === 200) {

        }
    } catch (error) {
        yield put(Actions.LOAD_LOCATIONS_ERROR(error))
    }
}
export function* fetchUsers(action) {
    try {
        const data = yield call(Service.getUsers, action.payload.customerId, action.payload.locationId);
        yield put(Actions.LOAD_USERS_SUCCESS_TYPE(data))
        if (data.status === 200) {

        }
    } catch (error) {
        yield put(Actions.LOAD_USERS_ERROR(error))
    }
}
export function* addUser(action) {
    try {
        const data = yield call(Service.addUser, action.payload.user);
        yield all([
            put(Actions.ADD_USER_SUCCESS_TYPE(data)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "success",
                message: "User added succesfully",
                show: true
            }))
        ]);
        yield put(Actions.ADD_USER_SUCCESS_TYPE(data))
        if (data.status === 200) {

        }
    } catch (error) {
        yield all([
            put(Actions.ADD_USER_ERROR(error.response)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "success",
                message: "Error in adding user",
                show: true
            }))
        ]);
    }
}
export function* editUser(action) {
    try {
        const data = yield call(Service.editUser, action.payload.user, action.payload.userId);
        yield all([
            put(Actions.EDIT_USER_SUCCESS_TYPE(data)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "User updated successfully",
                show: true
            }))
        ]);

        if (data.status === 200) {

        }
    } catch (error) {
        yield all([
            put(Actions.EDIT_USER_ERROR(error.response)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in editing user",
                show: true
            }))
        ]);
    }
}
export function* fetchDockDoor(action) {
    try {
        const data = yield call(Service.fetchDockDoor, action.payload.dcId);
        yield put(Actions.LOAD_DOCK_DOOR_SUCCESS(data));


    } catch (error) {
        yield all([
            put(Actions.LOAD_DOCK_DOOR_ERROR(error))
        ]);
    }

}
export function* addDockDoor(action) {
    try {
        const data = yield call(Service.addDockDoor, action.payload.dockDoorName, action.payload.slsPortalIp, action.payload.topic, action.payload.rpDeviceIp, action.payload.selectedEdgeSerials, action.payload.status, action.payload.customerId, action.payload.dcId)
        yield put(Actions.CREATE_DOCK_DOOR_SUCCESS(data))

    } catch (error) {
        if (error.response.status === 409) {
            yield all([
                put(EdgePCActions.ADD_EDGE_PC_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error due to : Ip address conflicts with another active device at this distribution center.",
                    show: true
                }))
            ]);
        }
        else {
            yield all([
                put(Actions.CREATE_DOCK_DOOR_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in Creating Edge PC",
                    show: true
                }))
            ]);
        }

    }
}
export function* editDockDoor(action) {
    try {
        const data = yield call(Service.editDockDoor, action.payload.dockDoorId, action.payload.dockDoorName, action.payload.slsPortalIp, action.payload.topic, action.payload.rpDeviceIp, action.payload.selectedEdgeSerials, action.payload.status, action.payload.customerId, action.payload.dcId)
        yield put(Actions.EDIT_DOCK_DOOR_SUCCESS(data))
    } catch (error) {
        if (error.response.status === 409) {
            yield all([
                put(EdgePCActions.ADD_EDGE_PC_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error due to : Ip address conflicts with another active device at this distribution center.",
                    show: true
                }))
            ]);
        }
        else {
            yield all([
                put(Actions.CREATE_DOCK_DOOR_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in Editing Edge PC",
                    show: true
                }))
            ]);
        }
    }
}
export function* fetchnetworkConfiguration(action) {
    try {
        const data = yield call(Service.fetchnetworkConfiguration, action.payload.dcId)
        yield all([
            put(Actions.LOAD_NETWORK_CONFIGURATION_WITH_SUCCESS(data)),
        ]);

    } catch (error) {
        if (error.response.Error === 404) {
            yield put(Actions.LOAD_NETWORK_CONFIGURATION_WITH_ERROR(error))
        }
        else {
            yield all([
                put(Actions.LOAD_NETWORK_CONFIGURATION_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in loading networkConfiguration. Please try again.",
                    show: true
                }))
            ]);
        }

    }

}
export function* addNetworKConfiguration(action) {
    try {
        const data = yield call(Service.addNetworKConfiguration, action.payload.customerId, action.payload.dcId, action.payload.dnsAddress, action.payload.gatewayAddress, action.payload.netmaskAddress)
        yield put(Actions.CREATE_NETWORK_CONFIGURATION_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedNetworkConfigurationList()
        }
    } catch (error) {
        yield put(Actions.CREATE_NETWORK_CONFIGURATION_ERROR(error))
    }
}
export function* editNetworKConfiguration(action) {
    try {
        const data = yield call(Service.editNetworKConfiguration, action.payload.customerId, action.payload.dcId, action.payload.dnsAddress, action.payload.networkConfigID, action.payload.gatewayAddress, action.payload.netmaskAddress)
        yield put(Actions.EDIT_NETWORK_CONFIGURATION_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedNetworkConfigurationList()
        }
    } catch (error) {
        yield put(Actions.EDIT_NETWORK_CONFIGURATION_ERROR(error))
    }
}
export function* fetchEdgePcConfiguration2(action) {
    try {
        const data = yield call(Service.fetchEdgePcConfiguration, action.payload.edgePcId)
        yield put(Actions.LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_SUCCESS(data))

    } catch (error) {
        yield all([
            put(Actions.LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_ERROR(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in fetching EdgePC Configuration . Please try again.",
                show: true
            }))
        ]);
    }
}
export function* fetchpalletConfiguration(action) {
    try {
        const data = yield call(Service.fetchpalletConfiguration, action.payload.customerId, action.payload.dcId)
        yield all([
            put(Actions.LOAD_PALLET_CONFIGURATION_WITH_SUCCESS(data)),
        ]);

    } catch (error) {
        if (error.response.Error === 404) {
            yield put(Actions.LOAD_PALLET_CONFIGURATION_WITH_ERROR(error))
        }
        else {
            yield all([
                put(Actions.LOAD_PALLET_CONFIGURATION_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in loading networkConfiguration. Please try again.",
                    show: true
                }))
            ]);
        }

    }

}
export function* addPalletConfiguration(action) {
    try {
        const data = yield call(Service.addPalletConfiguration, action.payload.customerId, action.payload.dcId, action.payload.palletType, action.payload.palletSchema, action.payload.palletWeight, action.payload.isfullPallet)
        yield put(Actions.CREATE_PALLET_CONFIGURATION_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedPalletConfig()
        }

    } catch (error) {
        if (error.response.Error === 403) {
            yield put(Actions.CREATE_PALLET_CONFIGURATION_ERROR(error))
        }
        else {
            yield all([
                put(Actions.CREATE_PALLET_CONFIGURATION_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error due to Duplicate pallet type.",
                    show: true
                }))
            ]);

        }

    }
}
export function* editPalletConfiguration(action) {
    try {
        const data = yield call(Service.editPalletConfiguration, action.payload.customerId, action.payload.dcId, action.payload.palletType, action.payload.palletSchema, action.payload.palletWeight, action.payload.isfullPallet, action.payload.palletSchemaID)
        yield put(Actions.EDIT_PALLET_CONFIGURATION_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedPalletConfig()
        }
    } catch (error) {
        yield put(Actions.EDIT_PALLET_CONFIGURATION_ERROR(error))
    }
}

export function* fetchCodeDeploy(action) {
    try {
        const data = yield call(Service.fetchCodeDeploy, action.payload.dcId)
        yield all([
            put(Actions.LOAD_CODE_DEPLOYMENT_WITH_SUCCESS(data)),
        ]);

    } catch (error) {
        if (error.response.Error === 404) {
            yield put(Actions.LOAD_CODE_DEPLOYMENT_WITH_ERROR(error))
        }
        else {
            yield all([
                put(Actions.LOAD_CODE_DEPLOYMENT_WITH_ERROR(error)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: "error",
                    message: "Error in loading code deployment. Please try again.",
                    show: true
                }))
            ]);
        }

    }

}
export function* createCodeDeploy(action) {
    try {
        const data = yield call(Service.createCodeDeploy, action.payload.customerId, action.payload.dcId, action.payload.dayOfTheWeek, action.payload.startTime, action.payload.endTime)
        yield put(Actions.CREATE_CODE_DEPLOYMENT_WITH_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedCodeDeployList()
        }

    } catch (error) {
        yield all([
            put(Actions.CREATE_CODE_DEPLOYMENT_WITH_ERROR(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error",
                show: true
            }))
        ]);

    }
}
export function* editCodeDeploy(action) {
    try {
        const data = yield call(Service.editCodeDeploy, action.payload.customerId, action.payload.dcId, action.payload.dayOfTheWeek, action.payload.startTime, action.payload.endTime, action.payload.codeDeployWindowId)
        yield put(Actions.EDIT_CODE_DEPLOYMENT_WITH_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedCodeDeployList()
        }
    } catch (error) {
        yield put(Actions.EDIT_CODE_DEPLOYMENT_WITH_ERROR(error))
    }
}
export function* deleteCodeDeploy(action) {
    try {
        const data = yield call(Service.deleteCodeDeploy, action.payload.codeDeployWindowId)
        yield put(Actions.DELETE_CODE_DEPLOYMENT_WITH_SUCCESS(data))
        if (data.status === 200) {
            action.payload.callUpdatedCodeDeployList()
        }
    } catch (error) {
        yield put(Actions.DELETE_CODE_DEPLOYMENT_WITH_ERROR(error))
    }
}

export function* createEdgeDeploy(action) {
    try {
        const data = yield call(Service.createEdgeDeploy, action.payload.selectedCustomerId, action.payload.selectedLocationId, action.payload.selectedEdgePcId, action.payload.deploymentType, action.payload.modelSetid, action.payload.scheduleDate, action.payload.applicationId, action.payload.isFullCustomerDeploy, action.payload.isFullDcDeploy, action.payload.isEdgePcDeploy)
        yield put(Actions.CREATE_EDGE_DEPLOYMENT_WITH_SUCCESS(data))
        if (data.status === 200) {
            yield all([
                put(Actions.CREATE_EDGE_DEPLOYMENT_WITH_SUCCESS(data)),
                put(AppActions.SHOW_NOTIFICATION({
                    type: 'success',
                    message: "Success",
                    show: true
                }))
            ]);
        }

    } catch (error) {
        yield all([
            put(Actions.CREATE_EDGE_DEPLOYMENT_WITH_ERROR(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error",
                show: true
            }))
        ]);

    }
}

export function* fetchSiteHealthDetails(action) {
    try {
        const data = yield call(Service.fetchSiteHealthDetails, action.payload.selectedCustomerId, action.payload.selectedLocationId, action.payload.selectedTranDate)
        yield all([
            put(Actions.LOAD_SITE_HEALTH_METRICS_WITH_SUCCESS(data)),
        ]);

    } catch (error) {
        yield all([
            put(Actions.LOAD_SITE_HEALTH_METRICS_WITH_ERROR(error)),
            put(AppActions.SHOW_NOTIFICATION({
                type: "error",
                message: "Error in fetching site health details",
                show: true
            }))
        ]);

    }
}


export function* EffectListener() {
    yield takeEvery(ActionTypes.LOAD_APPLICATIONS, fetchApplications);
    yield takeEvery(ActionTypes.LOAD_CUSTOMERS, fetchCustomers);
    yield takeEvery(ActionTypes.CREATE_CUSTOMER, addCustomer);
    yield takeEvery(ActionTypes.EDIT_CUSTOMER, editCustomer);
    yield takeEvery(ActionTypes.LOAD_CUSTOMERS_WITH_DC, fetchCustomersWithDC);
    yield takeEvery(ActionTypes.LOAD_EDGEPCS, fetchEdgePC);
    yield takeEvery(ActionTypes.LOAD_EDGEPCS_CONFIGURATION, fetchEdgePcConfiguration);
    yield takeEvery(ActionTypes.LOAD_TANDEMS, fetchTandems);
    yield takeEvery(ActionTypes.ADD_EDGE_PC, addEdgePC);
    yield takeEvery(ActionTypes.EDIT_EDGE_PC, editEdgePC);
    yield takeEvery(ActionTypes.ADD_TANDEM, addTandem);
    yield takeEvery(ActionTypes.EDIT_TANDEM, editTandem);
    yield takeEvery(ActionTypes.DELETE_TANDEM, deleteTandem);
    yield takeEvery(ActionTypes.CREATE_LOCATION, addLocation);
    yield takeEvery(ActionTypes.EDIT_LOCATION, editLocation);
    yield takeEvery(ActionTypes.LOAD_LOCATIONS, fetchLocations);
    yield takeEvery(ActionTypes.LOAD_VOR_APPLICATIONS, fetchVORApplications);
    yield takeEvery(ActionTypes.CREATE_VOR_CONFIGURATION, addVorConfiguration);
    yield takeEvery(ActionTypes.EDIT_VOR_CONFIGURATION, editVorConfiguration);
    yield takeEvery(ActionTypes.COPY_CREATE_VOR_CONFIGURATION, addVorConfigurationsList);
    yield takeEvery(ActionTypes.LOAD_EDGE_SERIALS, fetchEdgeSerials);
    yield takeEvery(ActionTypes.LOAD_PROPERTY_NAMES, fetchPropertyNames);
    yield takeEvery(ActionTypes.LOAD_PROPERTY_NAMES_EDIT, fetchPropertyNamesEdit);
    yield takeEvery(ActionTypes.LOAD_BRANDS, fetchBrands);
    yield takeEvery(ActionTypes.LOAD_PACKAGE_TYPE, fetchPackageType);
    yield takeEvery(ActionTypes.UPDATE_WEIGHT, updateWeight);
    yield takeEvery(ActionTypes.MERGE_WEIGHT, mergeWeight);
    yield takeEvery(ActionTypes.LOAD_VOR_CUSTOMERS, fetchVorCustomers);
    yield takeEvery(ActionTypes.LOAD_ROLES, fetchRoles);
    yield takeEvery(ActionTypes.LOAD_USERS, fetchUsers);
    yield takeEvery(ActionTypes.ADD_USER, addUser);
    yield takeEvery(ActionTypes.EDIT_USER, editUser);
    yield takeEvery(ActionTypes.LOAD_DOCK_DOOR, fetchDockDoor);
    yield takeEvery(ActionTypes.CREATE_DOCK_DOOR, addDockDoor);
    yield takeEvery(ActionTypes.EDIT_DOCK_DOOR, editDockDoor);
    yield takeEvery(ActionTypes.LOAD_NETWORK_CONFIGURATION, fetchnetworkConfiguration);
    yield takeEvery(ActionTypes.CREATE_NETWORK_CONFIGURATION, addNetworKConfiguration);
    yield takeEvery(ActionTypes.EDIT_NETWORK_CONFIGURATION, editNetworKConfiguration);
    yield takeEvery(ActionTypes.LOAD_PALLET_CONFIGURATION, fetchpalletConfiguration);
    yield takeEvery(ActionTypes.CREATE_PALLET_CONFIGURATION, addPalletConfiguration);
    yield takeEvery(ActionTypes.EDIT_PALLET_CONFIGURATION, editPalletConfiguration);
    yield takeEvery(ActionTypes.LOAD_EDGEPCS_TWO, fetchEdgePC2);
    yield takeEvery(ActionTypes.LOAD_EDGEPCS_CONFIGURATION_TWO, fetchEdgePcConfiguration2);
    yield takeEvery(ActionTypes.LOAD_CODE_DEPLOYMENT, fetchCodeDeploy);
    yield takeEvery(ActionTypes.CREATE_CODE_DEPLOYMENT, createCodeDeploy);
    yield takeEvery(ActionTypes.EDIT_CODE_DEPLOYMENT, editCodeDeploy);
    yield takeEvery(ActionTypes.DELETE_CODE_DEPLOYMENT, deleteCodeDeploy);
    yield takeEvery(ActionTypes.LOAD_DEPLOYMENT_TYPES, fetchDeploymentTypes);
    yield takeEvery(ActionTypes.CREATE_EDGE_DEPLOYMENT, createEdgeDeploy);
    yield takeEvery(ActionTypes.LOAD_SKUSBY_DESC, fetchSkusByDesc);
    yield takeEvery(ActionTypes.LOAD_SKUSBY_REFRENCE, fetchSkuByRefrence);
    yield takeEvery(ActionTypes.LOAD_SITE_HEALTH_METRICS, fetchSiteHealthDetails);
}