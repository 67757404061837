export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const SET_USER_INFO = 'SET_USER_INFO';
export const LOAD_APPLICATIONS = 'Load Applications';
export const LOAD_APPLICATIONS_SUCCESS_TYPE = '[APPLICATIONS SUCCESS] Load Applications Success';
export const LOAD_APPLICATIONS_FAILURE_TYPE = '[APPLICATIONS FAILURE] Load Applications Failure';
export const LOAD_VOR_APPLICATIONS = 'Load VOR Applications';
export const LOAD_VOR_APPLICATIONS_SUCCESS_TYPE = '[APPLICATIONS SUCCESS] Load VOR Applications Success';
export const LOAD_VOR_APPLICATIONS_FAILURE_TYPE = '[APPLICATIONS FAILURE] Load VOR Applications Failure';
export const LOAD_EDGE_SERIALS = 'LOAD_EDGE_SERIALS';
export const LOAD_EDGE_SERIALS_WITH_SUCCESS = 'LOAD_EDGE_SERIALS_WITH_SUCCESS';
export const LOAD_EDGE_SERIALS_WITH_ERROR = 'LOAD_EDGE_SERIALS_WITH_ERROR';
export const LOAD_SITE_HEALTH_METRICS = 'Load Site Health Metrics';
export const LOAD_SITE_HEALTH_METRICS_WITH_SUCCESS = 'Load Site Health Metrics Success';
export const LOAD_SITE_HEALTH_METRICS_WITH_ERROR = 'Load Site Health Metrics Error';

export const LOAD_CUSTOMERS = 'Load Customers';
export const LOAD_CUSTOMERS_SUCCESS_TYPE = '[CUSTOMER SUCCESS] Load Customer Success';
export const LOAD_CUSTOMERS_ERROR ='Load Customer Error';
export const CUSTOMER_ADD_EDIT_MODAL_UPDATE = 'Add Edit Customer Modal';
export const CREATE_CUSTOMER = 'Create Customer';
export const CREATE_CUSTOMER_SUCCESS = 'Create Customer Success';
export const EDIT_CUSTOMER = 'Edit Customer';
export const EDIT_CUSTOMER_SUCCESS = 'Edit customer success';
export const CREATE_CUSTOMER_ERROR = 'Create customer error';
export const EDIT_CUSTOMER_ERROR = 'Edit customer error';
export const LOAD_CUSTOMERS_WITH_DC = 'LOAD_CUSTOMERS_WITH_DC';
export const LOAD_CUSTOMERS_WITH_DC_SUCCESS = '[CUSTOMER_WIT_HDC SUCCESS] LOAD_CUSTOMERS_WITH_DC';
export const LOAD_EDGEPCS = 'LOAD_EDGEPCS';
export const LOAD_EDGEPCS_WITH_SUCCESS = '[LOAD_EDGEPCS_WITH_SUCCESS] LOAD_EDGEPCS';
export const LOAD_EDGEPCS_WITH_ERROR = '[LOAD_EDGEPCS_WITH_ERROR] LOAD_EDGEPCS';
export const LOAD_EDGEPCS_TWO = 'LOAD_EDGEPCS 2';
export const LOAD_EDGEPCS_TWO_WITH_SUCCESS = '[LOAD_EDGEPCS_WITH_SUCCESS] LOAD_EDGEPCS 2';
export const LOAD_EDGEPCS_TWO_WITH_ERROR = '[LOAD_EDGEPCS_WITH_ERROR] LOAD_EDGEPCS 2';
export const ADD_EDGE_PC = 'ADD_EDGE_PC';
export const ADD_EDGE_PC_WITH_SUCCESS = '[ADD_EDGE_PC_WITH_SUCCESS] ADD_EDGE_PC_SUCCESS';
export const ADD_EDGE_PC_WITH_ERROR = '[ADD_EDGE_PC_WITH_ERROR] ADD_EDGE_PC_SUCCESS';
export const EDIT_EDGE_PC = 'EDIT_EDGE_PC';
export const EDIT_EDGE_PC_WITH_SUCCESS = '[EDIT_EDGE_PC_WITH_SUCCESS] EDIT_EDGE_PC_SUCCESS';
export const EDIT_EDGE_PC_WITH_ERROR = '[EDIT_EDGE_PC_WITH_ERROR] EDIT_EDGE_PC_SUCCESS';
export const SELECT_EDGEPC = 'SELECT_EDGEPC';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const SELECT_DISTRIBUTION_CENTER = 'SELECT_DISTRIBUTION_CENTER';
export const LOAD_TANDEMS = 'LOAD_TANDEMS';
export const LOAD_TANDEMS_WITH_SUCCESS = '[LOAD_TANDEMS_WITH_SUCCESS] LOAD_TANDEMS';
export const LOAD_TANDEMS_WITH_ERROR = '[LOAD_TANDEMS_WITH_ERROR] LOAD_TANDEMS';
export const LOAD_EDGEPCS_CONFIGURATION = 'Load edge pc configuration';
export const LOAD_EDGEPCS_CONFIGURATION_WITH_SUCCESS = 'Edge pc configuration success';
export const LOAD_EDGEPCS_CONFIGURATION_WITH_ERROR = 'Edge pc configuration error';

export const ADD_TANDEM = 'ADD_TANDEM';
export const ADD_TANDEM_WITH_SUCCESS = '[ADD_TANDEM_WITH_SUCCESS] ADD_TANDEM_SUCCESS';
export const ADD_TANDEM_WITH_ERROR = '[ADD_TANDEM_WITH_ERROR] ADD_TANDEM_SUCCESS';
export const EDIT_TANDEM = 'EDIT_TANDEM';
export const DELETE_TANDEM = 'DELETE_TANDEM';
export const EDIT_TANDEM_WITH_SUCCESS = '[EDIT_TANDEM_WITH_SUCCESS] EDIT_TANDEM_SUCCESS';
export const EDIT_TANDEM_WITH_ERROR = '[EDIT_TANDEM_WITH_ERROR] EDIT_TANDEM_SUCCESS';
export const SELECT_TANDEM = 'SELECT_TANDEM';


export const LOAD_LOCATIONS = 'Load Locations';
export const LOAD_LOCATIONS_SUCCESS_TYPE = 'Load Locations Success';
export const LOCATION_ADD_EDIT_MODAL = 'Add Edit Location Modal';
export const CREATE_LOCATION = 'Create Location';
export const CREATE_LOCATION_SUCCESS = 'Create Location Success';
export const CREATE_LOCATION_ERROR = 'Create Location error';
export const EDIT_LOCATION = 'Edit Location';
export const EDIT_LOCATION_SUCCESS = 'Create Location Success';
export const EDIT_LOCATION_ERROR = 'Create Location error';
export const CONFIGURATION_ADD_EDIT_MODAL = 'Load Configuration add/edit Modal';
export const COPY_CONFIGURATION_ADD_EDIT_MODAL = 'Copy Configuration Modal';
export const CREATE_VOR_CONFIGURATION = 'Create vor CONFIGURATION';
export const CREATE_VOR_CONFIGURATION_SUCCESS = 'Create vor CONFIGURATION Success';
export const CREATE_VOR_CONFIGURATION_ERROR = 'Create vor CONFIGURATION error';
export const COPY_CREATE_VOR_CONFIGURATION = 'Copy Create vor CONFIGURATION';
export const COPY_CREATE_VOR_CONFIGURATION_SUCCESS = 'Copy Create vor CONFIGURATION Success';
export const COPY_CREATE_VOR_CONFIGURATION_ERROR = 'Copy Create vor CONFIGURATION error';
export const EDIT_VOR_CONFIGURATION = 'Edit vor CONFIGURATION';
export const EDIT_VOR_CONFIGURATION_SUCCESS = 'Edit vor CONFIGURATION Success';
export const EDIT_VOR_CONFIGURATION_ERROR = 'Edit vor CONFIGURATION error';
export const LOAD_PROPERTY_NAMES = 'Load Property Names';
export const LOAD_PROPERTY_NAMES_SUCCESS = 'Load Property Names Success';
export const LOAD_PROPERTY_NAMES_ERROR = 'Load property names error';
export const LOAD_PROPERTY_NAMES_EDIT = 'Load Property Names edit';
export const LOAD_PROPERTY_NAMES_EDIT_SUCCESS = 'Load Property Names edit Success';
export const LOAD_PROPERTY_NAMES_EDIT_ERROR = 'Load property names edit error';
export const LOAD_BRANDS = 'Load Brands';
export const LOAD_BRANDS_SUCCESS = 'Load brands success';
export const LOAD_BRANDS_ERROR = 'Load brands error';
export const LOAD_PACKAGE_TYPE = 'Load package type';
export const LOAD_PACKAGE_TYPE_SUCCESS = 'Load package type success';
export const LOAD_PACKAGE_TYPE_ERROR = 'Load package type error';
export const UPDATE_WEIGHT = 'Update weight';
export const UPDATE_WEIGHT_SUCCESS = 'Update weight success';
export const UPDATE_WEIGHT_ERROR = 'Update weight error';
export const MERGE_WEIGHT = 'Merge weight';
export const MERGE_WEIGHT_SUCCESS = 'Merge weight success';
export const MERGE_WEIGHT_ERROR = 'Merge weight error';
export const LOAD_VOR_CUSTOMERS = 'Load Vor Customers';
export const LOAD_VOR_CUSTOMERS_SUCCESS = 'Load Vor Customers success';
export const LOAD_VOR_CUSTOMERS_ERROR = 'Load Vor Customers error';

export const LOAD_ROLES = 'Load Roles';
export const LOAD_ROLES_SUCCESS_TYPE = '[CUSTOMER ROLES] Load Roles Success';
export const LOAD_ROLES_ERROR ='Load Roles Error';
export const LOAD_APPLICATIONS_ERROR ='Load Applications Error';
export const LOAD_LOCATIONS_ERROR ='Load Location Error';
export const LOAD_USERS = 'Load Users';
export const LOAD_USERS_SUCCESS_TYPE = '[LOAD USERS] Load Users Success';
export const LOAD_USERS_ERROR ='[LOAD USERS] Load Users Error';
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS_TYPE = '[ADD_USER_SCUCESSS] Add User Success';
export const ADD_USER_ERROR ='ADD_USER_ERROR';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS_TYPE = '[EDIT_USER_SCUCESSS] EDIT User Success';
export const EDIT_USER_ERROR ='EDIT_USER_ERROR';
export const LOAD_DOCK_DOOR = 'Load Dock Door';
export const LOAD_DOCK_DOOR_SUCCESS = 'Load dock door success';
export const LOAD_DOCK_DOOR_ERROR = 'Load dock door error';
export const CREATE_DOCK_DOOR = 'Create DOCK DOOR';
export const CREATE_DOCK_DOOR_SUCCESS = 'Create dock door success';
export const CREATE_DOCK_DOOR_ERROR = 'Create DOCK DOOR Error';
export const EDIT_DOCK_DOOR = 'Edit Dock Door';
export const EDIT_DOCK_DOOR_SUCCESS = 'Edit Dock Door success';
export const EDIT_DOCK_DOOR_ERROR = 'Edit Dock Door Error';
export const LOAD_NETWORK_CONFIGURATION = 'Load network configuration';
export const LOAD_NETWORK_CONFIGURATION_WITH_SUCCESS = 'network configuration success';
export const LOAD_NETWORK_CONFIGURATION_WITH_ERROR = 'network configuration error';
export const NETWORK_CONFIGURATION_ADD_EDIT_MODAL = 'Load Network Configuration add/edit Modal';
export const CREATE_NETWORK_CONFIGURATION = 'Create network configuration';
export const CREATE_NETWORK_CONFIGURATION_SUCCESS = 'Create network CONFIGURATION Success';
export const CREATE_NETWORK_CONFIGURATION_ERROR = 'Create network CONFIGURATION error';
export const EDIT_NETWORK_CONFIGURATION = 'Edit network Configuration';
export const EDIT_NETWORK_CONFIGURATION_SUCCESS = 'Edit network Configuration Success';
export const EDIT_NETWORK_CONFIGURATION_ERROR = 'Edit network Configuration error';
export const LOAD_PALLET_CONFIGURATION = 'Load pallet configuration';
export const LOAD_PALLET_CONFIGURATION_WITH_SUCCESS = 'Load pallet configuration success';
export const LOAD_PALLET_CONFIGURATION_WITH_ERROR = 'Load pallet configuration error';
export const CREATE_PALLET_CONFIGURATION = 'Create pallet configuration';
export const CREATE_PALLET_CONFIGURATION_SUCCESS = 'Create pallet CONFIGURATION Success';
export const CREATE_PALLET_CONFIGURATION_ERROR = 'Create pallet CONFIGURATION error';
export const EDIT_PALLET_CONFIGURATION = 'Edit pallet Configuration';
export const EDIT_PALLET_CONFIGURATION_SUCCESS = 'Edit pallet Configuration Success';
export const EDIT_PALLET_CONFIGURATION_ERROR = 'Edit pallet Configuration error';
export const LOAD_EDGEPCS_CONFIGURATION_TWO = 'Load edge pc configuration 2';
export const LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_SUCCESS = 'Edge pc configuration success 2';
export const LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_ERROR = 'Edge pc configuration error 2';
export const LOAD_DEPLOYMENT_TYPES = 'Load deployment types';
export const LOAD_DEPLOYMENT_TYPES_WITH_SUCCESS = 'Load deployment types success';
export const LOAD_DEPLOYMENT_TYPES_WITH_ERROR = 'Load deployment types error';
export const LOAD_CODE_DEPLOYMENT = 'Load code deployment';
export const LOAD_CODE_DEPLOYMENT_WITH_SUCCESS = 'Load deployment success';
export const LOAD_CODE_DEPLOYMENT_WITH_ERROR = 'Load deployment error';
export const CREATE_CODE_DEPLOYMENT = 'Create code deployment';
export const CREATE_CODE_DEPLOYMENT_WITH_SUCCESS = 'Create deployment success';
export const CREATE_CODE_DEPLOYMENT_WITH_ERROR = 'Create deployment error';
export const EDIT_CODE_DEPLOYMENT = 'Edit code deployment';
export const EDIT_CODE_DEPLOYMENT_WITH_SUCCESS = 'Edit code deployment success';
export const EDIT_CODE_DEPLOYMENT_WITH_ERROR = 'Edit code deployment error';
export const DELETE_CODE_DEPLOYMENT = 'Delete code deployment';
export const DELETE_CODE_DEPLOYMENT_WITH_SUCCESS = 'Delete code deployment success';
export const DELETE_CODE_DEPLOYMENT_WITH_ERROR = 'Delete code deployment error';
export const CREATE_EDGE_DEPLOYMENT = 'Create edge deployment';
export const CREATE_EDGE_DEPLOYMENT_WITH_SUCCESS = 'Create edge deployment success';
export const CREATE_EDGE_DEPLOYMENT_WITH_ERROR = 'Create edge deployment error';
export const LOAD_SKUSBY_DESC = 'Load skus by description';
export const LOAD_SKUSBY_DESC_WITH_SUCCESS = 'Load skus by description success';
export const LOAD_SKUSBY_DESC_WITH_ERROR = 'Load skus by description error';
export const LOAD_SKUSBY_REFRENCE = 'Load sku by Refrence';
export const LOAD_SKUSBY_REFRENCE_WITH_SUCCESS = 'Load sku by Refrence success';
export const LOAD_SKUSBY_REFRENCE_WITH_ERROR = 'Load sku by Refrence error';
