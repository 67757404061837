import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

const DashBoardGridPanel = ({ user, ...props }) => {
    const [dailyData, setDailyData] = useState(props.dailyAnalysisData);
    const [customers, setCustomers] = useState();
    const [tranDate, setTranDate] = useState();

    useEffect(() => {
        if (props.dailyAnalysisData) {
            setDailyData(props.dailyAnalysisData);
            setCustomers(props.dailyAnalysisData.customers)
            setTranDate(props.dailyAnalysisData.date);
        }

    }, [props.dailyAnalysisData, customers]);

    let formatedCustomers = customers && customers.filter(item => {
        let customerName = item.customer_name !== null ? item.customer_name : '';
        item.dcs.forEach(el => {
            el.customer_name = customerName;
            return el;
        });

        return item;
    })
    let grouped = formatedCustomers && formatedCustomers.flatMap(obj => {
        return obj.dcs.map(dcs => dcs);
    });
    const columns = [
        { field: 'customer_name', headerName: 'Customer Name', width: 200 },
        { field: 'dc_name', headerName: 'DC Name', width: 200 },
        { field: 'total_pallets', headerName: 'Total Pallets', width: 100 },
        { field: 'un_train_p', headerName: 'Has Untrained SKU in Pallet', width: 200 },
        { field: 'vor_suc_percentage', headerName: 'VOR Success Rate %', width: 150 },
        { field: 'perr', headerName: 'Picker error %', width: 120 },
        { field: 'qc_bot_audit_rate_percentage', headerName: 'QC Bot Audit Rate %', width: 120 },
        { field: 'weight_heuristics_percentage', headerName: 'Weight heuristic(Erasor) %', width: 200 },
        { field: 'success_count_weight_tolerance_percentage', headerName: 'Success pallet out weight tolerence', width: 200 },
        { field: 'vor_verified_percentage', headerName: 'VOR verified %', width: 120 },
        { field: 'picklist_not_processed', headerName: 'Picklist not processed on VOR %', width: 200 },
        { field: 'system_error_percentage', headerName: 'System error pallet count', width: 200 },
        { field: 'bypass_percentage', headerName: 'BYPASS pallet count', width: 200 },
        { field: 'pic_err_miss_vor', headerName: 'Picker error missed by VOR', width: 200 }
    ];

    return (
        <>
            <div className="dashboardData-panel">
                <div className="duolift-device-detail-card" style={{ width: "100%" }}>
                    <div className="detail-card-header">
                        <h3 style={{ paddingTop: '10px' }}> Daily analysis summary : {tranDate ? tranDate : ''}</h3>
                    </div>
                    <div className="duolift-device-detail-card-body">
                        <div style={{ height: '440px', width: '100%' }}>
                            <DataGrid
                                rows={grouped ? grouped: ''}
                                columns={columns}
                                rowsPerPageOptions={[10]}
                                getRowId={(row) => row.dc_name}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.application.user
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        // showAddEditCustomerModal: (type, show, selectedData) => dispatch(Actions.CUSTOMER_ADD_EDIT_MODAL_UPDATE(type, show, selectedData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardGridPanel);